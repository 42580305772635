<template>
  <v-card class="ml-2" elevation="2" style="border-radius:7px;">
    <v-row dense>
      <v-col cols="6" class="d-flex align-center">
        <span class="font-weight-bold font-size14 mt-4 ml-2">{{$t('my-patients')}}</span>
      </v-col>
      <v-col cols="6" class="mt-2 pr-4">
      <Search v-model="search"></Search>
      </v-col>
      <v-col cols="12">
        <v-data-table
            :loading="loading"
            :headers="headers"
            :search="search"
            class="pointer"
            :no-data-text="$t('no-data-available')"
            :loading-text="$t('loading')"
            :items="getScoreReportsForPatients"
            dense
            :footer-props="footerProps"
            :items-per-page="-1"
            :height="pageHeight(46)"
            fixed-header
        >
          <template v-slot:item="{ item }">
            <tr @click="navigateToPatientDetails(item.patientId)"  style="overflow-y:auto">
              <td class="text-left align-center d-flex">
                <v-img :src="PatientBlack" max-width="13px" max-height="20px"/>
                <span class="ml-2 table-font-size">{{ item.patientFullname }}</span>
              </td>
              <td :class="(item.calculatedScore * 100)" class="text-right table-font-size">
                {{(item.calculatedScore * 100).toFixed(1)}}%
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import { mapGetters, mapState } from 'vuex';
import { patientBlack } from '@/assets';
import Search from '@/views/Client/vuetify-components/Search.vue';

export default {
  components: {
    Search,
  },
  data () {
    return {
      PatientBlack: patientBlack,
      search: '',
      loading: false,
      footerProps: { 'items-per-page-options': [-1, 5, 10, 50, 100] },
    };
  },
  computed: {
    ...mapState({
      reportsRepo: (state) => state.reportsRepo.allReports,
    }),
    ...mapGetters({
      pageHeight: 'pageHeight',
      getUserById: 'users/getUserById',
      hospitalId: 'authentication/getHospitalId',
    }),
    getScoreReportsForPatients () {
      return this.reportsRepo?.patientsScoreList;
    },
    headers () {
      return [
        { text: this.$t('name'), value: 'patientFullname', class: 'table-header', align: 'start pl-3' },
        { text: this.$t('success-rate'), value: 'calculatedScore', class: 'table-header', align: 'end' },
      ];
    },
  },
  async mounted () {
    await this.getReports();
  },
  methods: {
    async navigateToPatientDetails (patientId) {
      await this.$store.dispatch('users/getUserById', patientId);
      this.$router.push({ name: 'Patient Details', params: { id: patientId } });
    },
    async getReports () {
      const hospital = localStorage.getItem('selectedHospitalAssignedId');
      this.loading = true;
      const data = { filterCase: this.$cookies.get('datafilter'), hospitalId: hospital };
      await this.$store.dispatch('reportsRepo/getAllReportsRelatedPatients', data).then(res => {
        this.loading = false;
      });
    },
  },
};
</script>
